import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const MinimalFooter = () =>
    <Grid
        alignItems={"center"}
        alignContent={"center"}
        id={"footer"}
        direction={"column"}
        item
        container
    >
        <Grid
            lg={12}
            className={"copyrights"}
            item
        >
            <Typography
                align={"center"}
                component={"p"}
                variant={"subtitle1"}
            >
                <Link
                    href={"https://www.collegia.co.uk/"}
                    target={"_blank"}
                >
                    {new Date().getFullYear()} © {" "}
                    Collegia.co.uk
                </Link>
                <br />
                All rights reserved. Oxford, United Kingdom.
            </Typography>
        </Grid>
    </Grid>

/**
 * @returns {*}
 */
export default MinimalFooter;